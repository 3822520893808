/*
 * @Description:
 * @Author: shaojing
 * @Date: 2022-05-23 21:06:14
 * @LastEditors: shaojing
 * @LastEditTime: 2022-05-24 23:41:47
 */
// 首页管理相关接口
import request from '@/request/request'
// 查询banner列表
export const BannerShowBannersApi = (params) => request.post(`/admin/home/banner/showBanners`, params);
// 上架banner
export const BannerOnlinetApi = (params) => request.get(`/admin/home/banner/onLine`, { params });
// 下架banner
export const BannerOutApi = (params) => request.get(`/admin/home/banner/out`, { params });
// 删除banner
export const BannerDeleteApi = (params) => request.get(`/admin/home/banner/delete`, { params });
// 查看banner详情
export const BannerGetBannerApi = (params) => request.get(`/admin/home/banner/getBanner`, { params });
// 排序banner
export const BannerSortOperationApi = (params) => request.post(`/admin/home/banner/sortOperation`, params);
// banner新增
export const BannerSaveBannerApi = (params) => request.post(`/admin/home/banner/saveBanner`, params);
// banner修改
export const BannerUpdBannerApi = (params) => request.post(`/admin/home/banner/updBanner`, params);



// 热门好课列表
export const PageCourseBoutiqueResultApi = (params) => request.post(`/admin/course/boutique/pageCourseBoutiqueResult`, params);
// 删除热门好课
export const DelCourseBoutiqueApi = (params) => request.get(`/admin/course/boutique/delCourseBoutique`, { params });
// 排序热门好课
export const SortCourseBoutiqueApi = (params) => request.post(`/admin/course/boutique/sortCourseBoutique`, params);
// 查看热门好课详情
export const GetCourseBoutiqueResultApi = (params) => request.get(`/admin/course/boutique/getCourseBoutiqueResult`, { params });
// 新增热门好课
export const SaveBoutiqueApi = (params) => request.post(`/admin/course/boutique/saveCourseBoutique`, params);
// 修改热门好课
export const UpdBoutiqueApi = (params) => request.post(`/admin/course/boutique/updCourseBoutique`, params);



// 精品小课列表
export const PageAdminCourseHotResultApi = (params) => request.post(`/admin/course/hot/pageAdminCourseHotResult`, params);
// 删除精品小课列表
export const DelCourseHotApi = (params) => request.get(`/admin/course/hot/delCourseHot`, { params });
// 排序精品小课
export const SortCourseHotApi = (params) => request.post(`/admin/course/hot/sortCourseHot`, params);
// 添加精品小课
export const AddCourseHotApi = (params) => request.post(`/admin/course/hot/addCourseHot`, params);


// 每日申识列表
export const RecommendListApi = (params) => request.post(`/admin/home/recommend/list`, params);
// 每日申识新增
export const RecommendAddApi = (params) => request.post(`/admin/home/recommend/add`, params);
// 每日申识新增
export const RecommendUpdApi = (params) => request.post(`/admin/home/recommend/upd`, params);
// 每日申识下线
export const RecommendOutApi = (params) => request.get(`/admin/home/recommend/out/${params.id}`, params);
// 每日申识上线
export const RecommendOnlineApi = (params) => request.get(`/admin/home/recommend/onLine/${params.id}`, params);
// 每日申识删除
export const RecommendDelApi = (params) => request.get(`/admin/home/recommend/delete/${params.id}`, params);
// 每日申识详情
export const RecommendDetailsApi = (params) => request.get(`/admin/home/recommend/details/${params.id}`, params);
// 每日申识排序
export const RecommendSortApi = (params) => request.post(`/admin/home/recommend/sort`, params);




// 弹框课程tab列表数据
export const TabExistsCourseInfoApi = (params) => request.post(`/admin/course/info/tab/queryCourseInfoDuplicateRemoval`, params);
// 弹框会员tab列表数据
export const MemberTabApi = (params) => request.post(`/admin/member/tab`, params);
// 弹窗bannertab列表数据
export const QueryCourseInfoAllApi = (params) => request.post('/admin/course/info/tab/queryCourseInfoAll', params);


//获取首页分类列表
export const GetCateListApi = (params) => request.post(`/adminv2/getCateListDetail`, params);

//保存首页添加接口
export const AddSaveShopApi = (params) => request.post(`/adminv2/saveFirstCategory`, params);
//获取一级分类详细
export const GetCateDetailApi = (params) =>request.post(`/adminv2/getFirstCategoryDetail`,params)
//删除一级分类
export const DelCateApi = (params) =>request.post(`/adminv2/delFirstCategory`,params)
// banner修改
export const NewBannerUpdBannerApi = (params) => request.post(`/adminv2/saveBanner`, params);

//获取banner分类
export const GetBannerApi = (params) => request.post(`/adminv2/getBanner`, params);
// 取消上架下架
export const updateBannerStatusApi = (params) =>request.post(`/adminv2/updateBannerStatus`, params);
// 删除
export const delBannerApi = (params) =>request.post(`/adminv2/delBanner`, params);
export const BannerGetBannerApiV2 = (params) => request.post(`/adminv2/getOneBanner`, params);

// 活动弹窗设置接口
// 获取列表
export const GetHomeAdList = (params) => request.post(`/adminv2/home/getHomeAdList`, params);
// 获取广告详情
export const GetHomeAdInfo = (params) => request.post(`/adminv2/home/getHomeAdInfo`, params);
// 添加广告
export const AddHomeAd = (params) => request.post(`/adminv2/home/addHomeAd`, params);
// 删除广告
export const DelHomeAd = (params) => request.post(`/adminv2/home/delHomeAd`, params);
// 切换上下架
export const PutAwayAd = (params) => request.post(`/adminv2/home/putAwayAd`, params);

// 海报管理
// 海报列表
export const PlaybillList = (params) => request.post(`/adminv2/playbillList`, params);
// 添加海报
export const AddPlaybill = (params) => request.post(`/adminv2/addPlaybill`, params);
// 编辑海报
export const EditPlaybill = (params) => request.post(`/adminv2/editPlaybill`, params);
// 获取海报详情
export const GetPlaybill = (params) => request.post(`/adminv2/getPlaybill`, params);
// 海报上下架
export const SavePutawayFlag = (params) => request.post(`/adminv2/savePutawayFlag`, params);

// 首页活动配置列表
export const HomeActiveListAPI = (params) => request.post(`/adminv2/homeactivity/getLists`, params);
// 首页活动-添加
export const AddHomeActiveAPI = (params) => request.post(`/adminv2/homeactivity/add`, params);
// 首页活动-编辑
export const EditHomeActiveAPI = (params) => request.post(`/adminv2/homeactivity/edit`, params);
// 首页活动-发布
export const PushHomeActiveAPI = (params) => request.post(`/adminv2/homeactivity/release`, params);
// 首页活动-详情
export const GetHomeActiveDetailAPI = (params) => request.post(`/adminv2/homeactivity/getOne`, params);

// 签到/邀请海报
// 签到海报
export const getShopPlaybillList = (params) => request.post('/adminv2/shopplaybill/lists', params)
// 上架/下架
export const shopPlaybillRemoved = (params) => request.post('/adminv2/shopplaybill/removed', params)
// 详情
export const getOneShopPlaybill = (params) => request.post('/adminv2/shopplaybill/getOne', params)
// 添加
export const addShopPlaybill = (params) => request.post('/adminv2/shopplaybill/add', params)
// 编辑
export const editShopPlaybillList = (params) => request.post('/adminv2/shopplaybill/edit', params)

// 首页推荐管理
// 获取标题简介详情
export const getRecommendInfoAPI = (params) => request.get('/adminv2/recommend/title/info', params)
// 设置推荐标题
export const setRecommendInfoAPI = (params) => request.post('/adminv2/recommend/title/set', params)
// 获取推荐课程列表
export const getRecommendListAPI = (params) => request.get('/adminv2/recommend/course/list', params)
// 添加推荐课程
export const addRecommendCourseAPI = (params) => request.post('/adminv2/recommend/course/add', params)
// 删除推荐课程
export const delRecommendCourseAPI = (params) => request.post('/adminv2/recommend/course/del', params)